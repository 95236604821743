export const App = () => {
  return (
    <div className="main">
     

      
      <h3 className="white"><strong>Gravity:</strong></h3>
    
				<p> <span className="range-description white"><strong>Weak</strong></span> <input className="range-input" type="range" min="1" 
					max="100" /> <span className="range-description white"><strong>Strong</strong></span></p>
          <br />
          <h3 className="white"><strong>Electromagnetism:</strong></h3>
     
     <p> <span className="range-description white"><strong>Weak</strong></span> <input className="range-input" type="range" min="1" 
       max="100" /> <span className="range-description white"><strong>Strong</strong></span></p>
        <br />
       <h3 className="white"><strong>Strong nuclear interaction:</strong></h3>
     
     <p> <span className="range-description white"><strong>Weak</strong></span> <input className="range-input" type="range" min="1" 
       max="100" /> <span className="range-description white"><strong>Strong</strong></span></p>
        <br />
       <h3 className="white"><strong>Weak nuclear interaction:</strong></h3>
     
     <p> <span className="range-description white"><strong>Weak</strong></span> <input className="range-input" type="range" min="1" 
       max="100" /> <span className="range-description white"><strong>Strong</strong></span></p>
         <br />
       
    <a href="https://www.youtube.com/watch?v=UcgV4OFRS-w"><button className="create-universe-button btn-lg" ><strong>Create Universe</ strong></button></a>
  <a href="https://felix-scherf.de/impressum.html"> <p className="imprint"><strong>Imprint</strong></p> </a>

      </div>

  )
}